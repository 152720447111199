@font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff')
      format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Pretendard-Bold';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff')
      format('woff');
    font-weight: 700;
    font-style: normal;
  }
  
  .users-regist-container {
    width: calc(100vw - 300px);
    height: auto;
    /* background-color: #f1f3f9; */
  }
  
  .users-regist-contents {
    max-width: 1080px;
    width: 100%;
    margin: 80px auto 0 auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.25);
    font-family: 'Pretendard-Regular';
  }
  .users-regist-contents-top {
    background-color: #19277e;
    height: 88px;
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-family: 'Pretendard-Bold';
  }
  .users-regist-contents-top-inner {
      display: flex;
      width: 100%;
      justify-content: space-between;
  }
  .users-regist-contents-top-inner input {
      height: 28px;
      padding-left: 10px ;
  }
  .users-regist-contents-top-inner div {
      display: flex;
  }
  .users-regist-contents-top div div {
      margin: 30px 0 0 18px;
  }
  .users-regist-CircleUser {
      width: 26px;
      height: 26px;
      margin-right: 12px;
      margin-top: 3px;
  }
  .users-regist-Xmark {
    height: 24px;
    width: 24px;
    margin: 12px 14px 0 0;
  }
  .users-regist-contents-inner {
      width: 90%;
        max-width: 610px;
      margin: 35px auto 40px auto;
  }
  .users-regist-contents-inner h4 {
    font-family: 'Pretendard-Bold';
    margin-bottom: 10px;
  }
  .users-regist-info label {
      font-size: 14px;
      font-family: 'Pretendard-Bold';
      color: #19277e;
  }
  .users-regist-info p {
      font-size: 13px;
      font-family: 'Pretendard-Regular';
  }
  .users-regist-info div {
      display: flex;
  }
.users-regist-info-call label {
      margin-right: 76px;
  }
.users-regist-info-email label {
      margin-right: 88px;
  }
.users-regist-info-pw label {
      margin-right: 102px;
  }
.users-regist-info-field label{
      margin-right: 100px;
  }
.users-regist-info-belong label {
      margin-right: 127px;
  }
  .users-regist-info-birth label  {
      margin-right: 102px;
  }
.users-regist-info-join-day label {
      margin-right: 115px;
  }

.users-regist-info-call,
.users-regist-info-email,
.users-regist-info-pw,
.users-regist-info-field,
.users-regist-info-belong,
.users-regist-info-birth,
.users-regist-info-join-day {
    height: 43px;
    line-height: 43px;
    border-bottom: 1px solid #c4c4c4;
}

.users-regist-info-join-day {
    margin-bottom: 33px;
}
.users-regist-work-place div {
    width: 610px;
    height: 297px;
background-color: aqua;
}

.users-regist-info input {
    width: 300px;
    border: none;
}
.users-regist-info input:focus,
.users-regist-contents-top-inner input:focus {
    outline: none;
}
.users-regist-button {
    text-align: center;
    margin: 25px 0 0 0;
    padding-bottom: 22px;
}
.users-regist-button button {
    width: 126px;
    height: 40px;
    border-radius: 10px;
    border: none;
    background-color: #19277e;
    color: #fff;
    font-size: 15px;
    cursor: pointer;
}