.config-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.config-container .config-form{
    width: 50%;
    height: 50%;
    margin-top: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.config-container .config-form .config-info{
    width: 500px;
    height: 60px;
    padding: 5px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 20px;
}

.config-container .config-form .config-info .config-label{
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
}

.config-container .config-form .config-info .config-input{
    width: 70%;
    height: 60%;
    border: none;
    border-bottom: 1px solid lightgray;
    background-color: transparent;
    font-size: 20px;
}
.config-container .config-form .config-info .config-input:focus{
    outline: none;
}


.config-container .config-form .config-info .config-submit{
    width: 50%;
    height: 100%;
    background-color: transparent;
    border: 1px solid lightgray;
    border-radius: 10px;
    cursor: pointer;
}

.config-container .config-form .config-info .config-submit:hover{
    background-color: #c4c4c4;
    transition: background-color 1s;
}