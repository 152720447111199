.modal-layout-container{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 996;
    left: 0;
    top:0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-layout-container .modal-content{
    width: 1080px;
    min-height: 500px;
    background-color: white;
    /* padding: 1rem; */
    border-radius: 1vw;
}

.modal-layout-container .modal-content .title{
    width: 100%;
    background-color: #19277e;
    min-height: 70px;
    border-radius: 10px 10px 0 0;
    box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-family: 'Pretendard-Bold';
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-layout-container .modal-content .title .title-content{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 1.5%;
    align-items: center;
}

.modal-layout-container .modal-close-btn{
    cursor: pointer;
}

.modal-layout-container .modal-content .modal-body{
    width: 100%;
    padding: 1.5%;
}