@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff')
    format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard-Bold';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff')
    format('woff');
  font-weight: 700;
  font-style: normal;
}

.community-regist-container {
  width: calc(100vw - 300px);
  height: auto;
  /* background-color: #f1f3f9; */
}

.community-regist-contents {
  max-width: 1000px;
  width: 100%;
  margin: 80px auto 0 auto;
  /* border: 1px solid #f00; */
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.25);
  font-family: 'Pretendard-Regular';
}
.community-regist-contents-top {
  background-color: #19277e;
  height: 88px;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: space-between;
}
.community-regist-contents-top select {
  width: 186px;
  height: 35px;
  margin: 27px 0 26px 30px;
  border-radius: 4px;
  padding-left: 10px;
  font-size: 14px;
}

.Xmark {
  color: #fff;
  height: 24px;
  width: 24px;
  margin: 12px 14px 0 0;
  cursor: pointer;
}

.community-regist-contents-bottom {
  width: 95.5%;
  padding-bottom: 1%;
  margin: 39px auto 0 auto;
}
.community-regist-contents-bottom select {
  width: 186px;
  height: 35px;
  border-radius: 4px;
  padding-left: 10px;
  margin-right: 24px;
  border: 1px solid #c4c4c4;
  font-size: 14px;
}
.community-regist-contents-bottom input {
  width: 537px;
  height: 35px;
  border-radius: 4px;
  padding-left: 10px;
  border: 1px solid #c4c4c4;
}
.community-regist-contents-bottom input::placeholder,
.community-regist-contents-bottom textarea::placeholder {
  font-size: 12px;
}

.community-regist-contents-bottom textarea {
  width: 942px;
  height: 347px;
  border-radius: 4px;
  margin-top: 25px;
  margin-bottom: 32px;
  border: 1px solid #c4c4c4;
  padding: 10px 0 0 10px;
}
.community-regist-contents-button {
  text-align: center;
  margin-bottom: 30px;
}
.community-regist-contents-button button {
  width: 73px;
  line-height: 3;
  background-color: #19277e;
  margin-bottom: 10px;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-family: 'Pretendard-Bold';
  font-size: 12px;
}


.community-regist-contents-bottom .community_content{
  width: 942px;
  height: 347px;
  overflow: scroll;
  border-radius: 4px;
  margin-top: 25px;
  border: 1px solid #c4c4c4;
  padding: 10px 0 0 10px;
  white-space: pre-wrap;
}

.community-regist-contents-bottom .reply_head{
  width: 942px;
  line-height: 3;
  border-bottom: 1px solid #000;
}
.community-regist-contents-bottom .reploy_box{
  width: 942px;
  margin: 1% 0;
}

.community-regist-contents-bottom .reploy_box .reply_user{
  width: 100%;
  border-bottom: 1px solid #c4c4c4;
  line-height: 2;
}
.community-regist-contents-bottom .reploy_box .reply_user .reply_tiem{
  font-size: 0.75rem;
  color: #c4c4c4;
}
.community-regist-contents-bottom .reploy_box .reply_content{
  width: 100%;
  min-height: 50px;
  white-space: pre-wrap;
  border-bottom: 1px solid #c4c4c4;
  margin: 1% 0;
}

.community-regist-contents-bottom .reploy_content_empty{
  width: 942px;
  line-height: 3;
}