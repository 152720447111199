@font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff')
      format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Pretendard-Bold';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff')
      format('woff');
    font-weight: 700;
    font-style: normal;
  }
  
  .schedule-regist-container {
    /* width: calc(100vw - 300px); */
    min-width: 786px;
    height: auto;
    /* background-color: #f1f3f9; */
  }
  
  .schedule-regist-contents {
    max-width: 560px;
    width: 100%;
    margin: 80px auto 0 auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.25);
    font-family: 'Pretendard-Regular';
  }
  .schedule-regist-contents-top {
    background-color: #19277e;
    height: 70px;
    border-radius: 10px 10px 0 0;
    color: #fff;
    font-family: 'Pretendard-Bold';
  }
  .schedule-regist-contents-top-inner {
      display: flex;
      justify-content: space-between;
  }
  .schedule-regist-contents-top-inner h3 {
      font-size: 18px;
      line-height: 70px;
      margin-left: 27px;
  }
  .schedule-regist-contents-top-inner h4 {
      margin-right: 13px;
  }
  .schedule-regist-contents-top-inner div:nth-child(2) {
      display: flex;
      font-family: 'Pretendard-Regular';
      font-size: 14px;
      line-height: 25px;
      margin: 22px 27px 0 0;
  }
  .schedule-regist-CircleUser {
      width: 25px;
      height: 25px;
  }

  .schedule-regist-contents-inner{
      /* border: 1px solid #f00; */
      min-height: 350px;
  }

  .schedule-regist-info {
      width: 90%;
      height: 100%;
      margin: 36px auto;
  }
  .schedule-regist-info div {
      display: flex;
      margin-bottom: 32px;
  }
  .schedule-regist-info div:first-child {
      margin-bottom: 32px;
  }
  .schedule-regist-info div:nth-child(2) {
      margin-bottom: 32px;
  }
  .schedule-regist-info div:nth-child(3) {
      margin-bottom: 32px;
  }
  .schedule-regist-info div p:first-child {
      margin-right: 40px;
      font-family: 'Pretendard-Bold';
      font-size: 15px;
  }
  .schedule-regist-info div p:nth-child(2) {
      font-size: 13px;
      line-height: 15px;
  }
  .schedule-regist-info select {
      width: 150px;
      height: 32px;
      border-radius: 4px;
      border: none;
      background-color: #DAE6FE;
      padding-left: 5px;
      font-size: 12px;
      opacity: 0.6;
  }
  .schedule-regist-info div p{
      line-height: 32px;
  }
  /* .schedule-regist-info div:nth-child(2) p,
  .schedule-regist-info div:nth-child(3) p
  .schedule-regist-info div:nth-child(4) p
   {
      line-height: 32px;
  } */
  .schedule-regist-info input {
      width: 398px;
      height: 32px;
      border: none;
      border-radius: 4px;
      background-color: #DAE6FE;
      opacity: 0.6;
      padding-left: 10px;
  }
    .schedule-regist-info textarea{
    width: 398px;
    height: 96px;
    border: none;
    border-radius: 4px;
    background-color: #DAE6FE;
    opacity: 0.6;
    padding-left: 10px;
    padding-top: 10px;
}
  .schedule-regist-info input::placeholder ,.schedule-regist-info textarea::placeholder {
      font-size: 12px;
      color: #000;
  }
  .schedule-regist-button {
    text-align: center;
    margin: 25px 0 0 0;
    padding-bottom: 22px;
}
.schedule-regist-button .regist-btn {
    cursor: pointer;
    width: 73px;
    height: 30px;
    border-radius: 4px;
    border: none;
    background-color: #19277e;
    color: #fff;
    font-size: 12px;
}

.schedule-regist-button .cancel-btn {
    cursor: pointer;
    width: 73px;
    height: 30px;
    border-radius: 4px;
    border: none;
    background-color: #c5c5c5;
    color: #fff;
    font-size: 12px;
    margin-right: 10px;
}