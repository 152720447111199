* {
  padding: 0;
  margin: 0;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}


