@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff')
    format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard-Bold';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff')
    format('woff');
  font-weight: 700;
  font-style: normal;
}

.place-detail-container {
  width: calc(100vw - 300px);
  height: auto;
  background-color: #f1f3f9;
}

.place-detail-contents {
  max-width: 1200px;
  width: 100%;
  margin: 80px auto 0 auto;
}
.place-detail-dashboard {
  width: 100%;
  /* height: 740px; */
  /* background-color: rgb(136, 255, 255); */
  margin-bottom: 36px;
}
.place-detail-name,
.place-detail-plan,
.place-detail-holiday,
.place-detail-overtime,
.place-detial-overtime-allow,
.place-detial-work-users {
  display: flex;
  width: 100%;
  height: 55px;
  line-height: 55px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 4px;
}
.place-detail-name p:first-child,
.place-detail-plan p:first-child,
.place-detail-holiday p:first-child,
.place-detail-overtime p,
.place-detial-overtime-allow p:first-child,
.place-detial-work-users p:first-child {
  font-family: 'Pretendard-Bold';
  color: #19277e;
  margin: 0 151px 0 16px;
}
.place-detail-overtime p {
  margin-right: 121px;
}
.place-detial-overtime-allow p:first-child {
  margin-right: 88px;
}
.place-detial-work-users p:first-child {
  margin-right: 84px;
}
.place-detail-name p:last-child,
.place-detail-plan p:last-child,
.place-detail-holiday p:last-child,
.place-detial-overtime-allow p:last-child,
.place-detial-work-users p:last-child {
  font-family: 'Pretendard-Regular';
  font-size: 15px;
}
.place-detail-holiday {
  margin-bottom: 25px;
}
.place-detail-table {
  width: 100%;
  height: 278px;
  background-color: rgb(136, 255, 255);
  margin-bottom: 25px;
}
.place-detail-work-type {
  width: 100%;
  height: 200px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 25px;
}
.place-detail-work-type-selcet p {
  display: inline-block;
  font-family: 'Pretendard-Bold';
  color: #19277e;
  margin: 24px 165px 0 16px;
}
.place-detail-work-type-detail {
  font-size: 13px;
  font-family: 'Pretendard-Regular';
  margin: 25px 0 0 222px;
}
.place-detail-work-type-detail div {
  margin-bottom: 14px;
}
.place-detail-work-type-detail p {
  width: 85px;
  display: inline-block;
}
.place-detail-work-type-selcet label {
  margin-right: 30px;
}
.place-detail-overtime label {
  margin-right: 30px;
  line-height: 55px !important;
}
.place-detail-list-2 .place-detail-overtime label:before {
  top: 30%;
}
.place-detail-list-2 .place-detail-overtime label:after {
  top: 40%;
}
.place-detail-work-time {
  background-color: #fff;
  border-radius: 10px;
  margin-top: 25px;
  margin-bottom: 60px;
  padding-bottom: 25px;
  font-family: 'Pretendard-Regular';
}
.place-detail-work-time-top {
  padding: 20px 0 0 0;
  margin-bottom: 96px;
  display: flex;
}
.place-detail-work-time-top p {
  font-size: 14px;
  margin: 0 331px 0 16px;
}
.place-detail-work-time-top h3 {
  text-align: center;
  font-size: 20px;
  margin-right: 20px;
  font-family: 'Pretendard-Bold';
}
.place-detail-work-time-top span {
  margin-right: 5px;
  color: #19277e;
}
.place-detail-work-time-angle-icon {
  height: 19px;
  margin-right: 20px;
  vertical-align: top;
  color: #c4c4c4;
}
.place-detail-work-time-graph-wrap {
  display: flex;
  justify-content: center;
}
.place-detail-work-time-graph-vertical {
  text-align: center;
}
.place-detail-work-time-graph-vertical div {
  height: 45px;
  margin-right: 36px;
}
.place-detail-work-time-graph-horizontal {
  display: flex;
  margin-bottom: 60px;
  margin-left: 5px;
  justify-content: center;
}
.place-detail-work-time-graph-horizontal div {
  width: 155px;
  text-align: center;
}
.place-detail-work-time-button {
  text-align: right;
  margin-right: 78px;
}
.place-detail-work-time-button button {
  width: 126px;
  height: 30px;
  margin-bottom: 25px;
  font-size: 14px;
  border-radius: 10px;
  border: none;
  background-color: #19277e;
  color: #fff;
}
.place-detail-work-time-table table {
  width: 87%;
  margin: 0 auto;
  height: 342px;
  background-color: #f8f9fc;
  border-radius: 10px;
  border-spacing: 0;
}
.place-detail-work-time-table table thead {
  height: 69px;
  background-color: #19277e;
  color: #fff;
  font-size: 13px;
  font-family: 'Pretendard-Bold';
}
.place-detail-work-time-table table thead tr th:first-child {
  border-radius: 10px 0 0 0;
}
.place-detail-work-time-table table thead tr th:last-child {
  border-radius: 0 10px 0 0;
}
.place-detail-work-time-table table tbody {
  vertical-align: top;
  text-align: center;
  font-size: 13px;
  font-family: 'Pretendard-Regular';
}
.place-detail-work-time-table table tbody tr td {
  padding-top: 26px;
}
.place-detail-work-time-table table tfoot {
  height: 40px;
  background-color: #dce3f0;
  width: 87%;
  text-align: center;
  font-size: 14px;
  font-family: 'Pretendard-Bold';
}
.place-detail-work-time-table table tfoot tr th:nth-child(2) {
  padding-left: 25px;
  text-align: left;
}
.place-detail-work-time-table table tfoot tr th:first-child {
  border-radius: 0 0 0 10px;
}
.place-detail-work-time-table table tfoot tr th:last-child {
  border-radius: 0 0 10px 0;
}
