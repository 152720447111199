.calendar-container{
    width: 100%;
    height: auto;
}

.calendar-container .calendar-top{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.calendar-container .calendar-top .calendar-top-title{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
}
.calendar-container .calendar-top .calendar-top-title h3{
    font-size: 24px;
}

.calendar-container .calendar-top .calendar-top-title .user-calendar-faAngleLeft,
.calendar-container .calendar-top .calendar-top-title .user-calendar-faAngleRight {
  width: 24px;
  height: 24px;
  margin-right: 35px;
  margin-left: 35px;
  margin-top: 3px;
  cursor: pointer;
}


/* #2057FD */