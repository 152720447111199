.select-box{
    width: 100%;
    min-height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.select-box .box-wrapper{
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.select-box .box{
    width: 150px;
    height: 140px;
    border: 1px solid lightgray;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.select-box .box.active{
    border: 1px solid #19277e;
}

.select-box .box .box-icon{
    font-size: 35px;
    margin-bottom: 5px;
}

.select-box .box.active .box-icon{
    font-size: 35px;
    margin-bottom: 5px;
    color: #19277e;
}

.select-box .box .box-text{
    font-size: 15px;
}

.select-box .box.active .box-text{
    font-size: 15px;
    color: #19277e;
}