.place-contents-bottom .no-columns {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    color: #c4c4c4;
    border-top: none;
    border-radius: 0 0 10px 10px;
    /* border: 1px solid #c4c4c4; */
}

.place-contents-bottom .no-columns .no-columns-img {
    width: 100%;
    height: 150px;
    max-height: 150px;
    object-fit: contain;
    margin: 5px;
}