.notfound-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.notfound-container .numbers{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 2;
    font-size: 5rem;
}

.notfound-container .desc{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 3;
}

.notfound-container .btn{
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #19277e;
    color: white;
    line-height: 3;
    cursor: pointer;
}