@font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff')
      format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Pretendard-Bold';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff')
      format('woff');
    font-weight: 700;
    font-style: normal;
  }
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap');
  
  .place-calendar-container {
    width: calc(100vw - 300px);
    height: auto;
    background-color: #f1f3f9;
  }
  
  .place-calendar-contents {
    max-width: 1200px;
    width: 100%;
    margin: 80px auto 100px auto;
    font-family: 'Pretendard-Regular';
  }
  .place-calendar-contents-top-title {
    display: flex;
    justify-content: center;
    margin-bottom: 44px;
  }
  .place-calendar-contents-top-title h3{
    font-size: 24px;
    font-family: 'Pretendard-Bold';
  }
  .place-calendar-faAngleLeft, .place-calendar-faAngleRight {
    width: 24px;
    height: 24px;
    margin-right: 35px;
    margin-left: 35px;
    margin-top: 3px;
  }
  .place-calendar-contents-top-text {
    display: flex;
    justify-content: center;
    margin-bottom: 44px;
  }
  .place-calendar-contents-top-text p {
    font-family: 'Pretendard-SemiBold';
    font-size: 15px
  }
  .place-calendar-contents-top-text div {
    display: flex;
    justify-content: center;
    margin-right: 25px;
  }
  .place-calendar-contents-top-text div:last-child {
    margin-right: 0;
  } 

  .place-calendar-contents-top-text div p:first-child {
    color: #2057FD;
    background-color: #DAE6FE;
    width: 130px;
    height: 31px;
    border-radius: 10px;
    line-height: 31px;
    text-align: center;
  }
.place-calendar-contents-top-text-name {
  color: #000 !important;
  background-color: transparent !important;
  width: 106px !important;
}
.place-calendar-contents-top-text div p:nth-child(2) {
  font-size: 18px;
  line-height: 31px;
  margin-left: 10px;
}
.place-calendar-contents-top-text div p span {
  font-size: 13px;
  font-family: 'Pretendard-Regular';
}
  .place-calendar-contents-calendar {
    text-align: center;
  }
  .place-calendar-contents-calendar table {
    border-spacing:0;
    border-radius: 5px;
  }
  .place-calendar-contents-calendar thead tr td {
    border-bottom: 1px solid #c4c4c4;
    border-top: 1px solid #c4c4c4;
    font-size: 17px;
  }
  .place-calendar-contents-calendar thead tr td:first-child {
    border-left: 1px solid #c4c4c4;
    color: #DF5555;
    border-radius: 4px 0 0 0;
  }
  .place-calendar-contents-calendar thead tr td:last-child {
    border-right: 1px solid #c4c4c4;
    color: #2057FD;
    border-radius: 0 4px 0 0;
  }
  .place-calendar-contents-calendar thead tr td {
    width: 173px;
    height: 78px;
    text-align: left;
    padding-left: 20px;
    background-color: #DAE6FE;
  }
  .place-calendar-contents-calendar table tbody tr td {
    width: 173px;
    height: 149px;
    text-align: left;
    vertical-align: top;
    padding: 20px 0 0 20px;
    border: 1px solid #c4c4c486;
    background-color: #fff;
    font-family: 'Montserrat';
  }
  .place-calendar-contents-calendar table tbody tr td:first-child {
    color: #DF5555;
  }
  .place-calendar-contents-calendar table tbody tr td:last-child {
    color: #2057FD;
  }

  .calendar-1st td:first-child,
  .calendar-1st td:nth-child(2),
  .calendar-1st td:nth-child(3),
  .calendar-1st td:nth-child(4),
  .calendar-1st td:nth-child(5),
  .calendar-1st td:nth-child(6),
  .calendar-6th td:nth-child(3),
  .calendar-6th td:nth-child(4),
  .calendar-6th td:nth-child(5),
  .calendar-6th td:nth-child(6),
  .calendar-6th td:nth-child(7)
   {
    opacity: .4;
  }

  .calendar-4th td:nth-child(3),
  .calendar-2nd td:nth-child(2),
  .calendar-3rd td:nth-child(6) {
    position: relative;
    z-index: 9;
  }

  .calendar-2nd div {
    position: absolute;
    z-index: 10;
    background-color: #FEDAE3;
    margin: 0;
    width: 130px;
    margin: 15px 0 0 0;
    border-radius: 10px;
    border: 1px solid #DF5576;
  } 
  .calendar-3rd td:nth-child(3) div{
    position: absolute;
    z-index: 10;
    background-color: #DAE6FE;
    margin: 0;
    width: 300px;
    height: 100px;
    margin: 15px 0 0 0;
    border-radius: 10px;
    border: 1px solid #556FDF;
  }
  .calendar-3rd td:nth-child(6) div div:first-child{
    position: absolute;
    z-index: 10;
    background-color: #FEDAE3;
    margin: 0;
    width: 300px;
    height: 15px;
    margin: 15px 0 0 0;
    border-radius: 4px;
    border: 1px solid #DF5576;
  }
  .calendar-3rd td:nth-child(6) div div:nth-child(2){
    position: absolute;
    z-index: 10;
    background-color: #DAE6FE;
    margin: 0;
    width: 130px;
    height: 15px;
    margin: 32px 0 0 0;
    border-radius: 4px;
    border: 1px solid #556FDF;
  }
  .calendar-3rd td:nth-child(6) div div:nth-child(3){
    position: absolute;
    z-index: 10;
    background-color: #FEF4DA;
    margin: 0;
    width: 130px;
    height: 15px;
    margin: 49px 0 0 0;
    border-radius: 4px;
    border: 1px solid #DFA855;
  }
  .calendar-3rd td:nth-child(6) div div p,
  .calendar-5th td:nth-child(5) div div p {
    font-size: 12px;
    padding-left: 20px;
  }
  .calendar-4th td:nth-child(2) div {
    background-color: #FEF4DA;
    border: 1px solid #DFA855;
  }
  .calendar-4th div {
    position: absolute;
    z-index: 10;
    background-color: #DAE6FE;
    margin: 0;
    width: 130px;
    margin: 15px 0 0 0;
    border-radius: 10px;
    border: 1px solid #556FDF;
  }
  .calendar-5th td:nth-child(5) div div:first-child{
    position: absolute;
    z-index: 10;
    background-color: #FEDAE3;
    margin: 0;
    width: 130px;
    height: 15px;
    margin: 8px 0 0 0;
    border-radius: 4px;
    border: 1px solid #DF5576;
  }
  .calendar-5th td:nth-child(5) div div:nth-child(2) {
    position: absolute;
    z-index: 10;
    background-color: #DAE6FE;
    margin: 0;
    width: 130px;
    height: 70px;
    margin: 25px 0 0 0;
    border-radius: 4px;
    border: 1px solid #556FDF;
  }
  .calendar-5th td:nth-child(5) div div:nth-child(3){
    position: absolute;
    z-index: 10;
    background-color: #FEF4DA;
    margin: 0;
    width: 130px;
    height: 15px;
    margin: 97px 0 0 0;
    border-radius: 4px;
    border: 1px solid #DFA855;
  }
  .calendar-5th td:nth-child(5) div div:nth-child(2) h3 {
    margin: 16px 20px 0px 20px;
  }

  .calendar h3 {
    font-size: 17px;
    margin: 28px 20px 0px 20px;
  }
  .calendar h4 {
    font-size: 13px;
    margin: 0 20px 28px 20px;
    font-weight: 300;
  }