.place-dashboard-top {
    /* margin-left: 210px; */
    /* margin-right: 210px; */
}

.place-dashboard-top-title {
    display: flex;
    margin-top: 80px;
    margin-bottom: 35px;
}

.place-dashboard-top-title h2 {
    font-family: 'Pretendard-Bold';
    font-size: 20px;
    line-height: 27px;
    margin-right: 16px;
}

.place-dashboard-top-title h3 {
    font-family: 'Pretendard-Regular';
    font-size: 13px;
    background-color: #19277e;
    border-radius: 50px;
    width: 182px;
    height: 27px;
    line-height: 27px;
    color: #fff;
    text-align: center;
}

.place-dashboard-top-inner {
    display: flex;
    margin-bottom: 80px;
}

.place-dashboard-top-inner-box {
    display: flex;
    background-color: #fff;
    width: 218px;
    height: 90px;
    border-radius: 10px;
    margin-right: 27.5px;
}

.place-dashboard-top-inner-box:last-child {
    margin-right: 0;
}

.place-dashboard-top-inner-box-img {
    margin: auto 58px auto 18px;
}

.place-dashboard-top-inner-box-txt {
    margin: auto 0 auto 0;
}

.place-dashboard-top-inner-box-txt p:first-child {
    color: #a3aed0;
    font-size: 13px;
    font-family: 'Pretendard-Regular';
    margin-bottom: 11px;
}

.place-dashboard-top-inner-box-txt p:nth-child(2) {
    font-size: 17px;
    font-family: 'Pretendard-Bold';
}

.place-dashboard-top-inner-box-txt span {
    font-size: 34px;
    margin-right: 20px;
}