@charset "utf-8";

/* 스타일 초기화 */
* {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: #333;
  display: block;
}

@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff')
    format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard-Bold';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff')
    format('woff');
  font-weight: 700;
  font-style: normal;
}

.homelayout-container {
  width: 100%;
  height: 100%;
}

.home-login-container {
  background-color: #fff;
  display: flex;
  height: 100%;
}
.home-login-left-wrap {
  background-color: aqua;
  background-image: url(../../../Assets/img/loginbg.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  max-width: 1920px;
  width: 59%;
  padding: 62px 0 0 100px;
  position: relative;
  z-index: 0;
}
.home-login-left-wrap::before {
  content: '';
  width: 100%;
  height: 100%;
  background: linear-gradient(50deg, #021d50d8 50%, #2467e4a8);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.home-login-left-wrap .home-login-left-top img {
  width: 291px;
}
.home-login-left-bottom {
  position: absolute;
  top: 47%;
  left: 6%;
}
.home-login-left-bottom h1 {
  font-size: 70px;
  color: #fff;
  font-family: 'Pretendard-Bold';
  margin-bottom: 75px;
  line-height: 90px;
}
.home-login-left-bottom p {
  font-size: 30px;
  color: #fff;
  font-family: 'Pretendard-Regular';
  margin-bottom: 128px;
}
.home-login-right-wrap {
  width: 41%;
  background-color: #fff;
  position: relative;
}
.home-login-right {
  width: 400px;
  /* background-color: aqua; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.home-login-right h2 {
  font-size: 44px;
  color: #042e7d;
  margin-bottom: 130px;
  font-family: 'Pretendard-Bold';
}
.home-login-right .home-login-box {
  border-bottom: 2px solid #042e7d;
  display: block;
  width: 400px;
  margin: 0 auto;
  margin-bottom: 60px;
}
.home-login-right .home-login-box:nth-child(3) {
  margin-bottom: 40px;
}
.home-login-right .home-login-box input {
  outline-style: none;
  background-color: transparent;
  border: none;
  width: 390px;
  margin-bottom: 10px;
  font-size: 14px;
}
.home-login-right .home-login-box input::placeholder {
  font-family: 'Pretendard-Regular';
  color: #2e356e;
}
.home-login-checkbox {
  width: 400px;
  margin: 0 auto;
  text-align: left;
  margin-bottom: 40px;
  font-family: 'Pretendard-Regular';
  color: #2e356e;
  display: inline-block;
}
.home-login-checkbox p {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
}
.home-login-checkbox input {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  /* border: 2px solid #042e7d; */
}
.home-login-button button {
  width: 400px;
  height: 60px;
  border-radius: 10px;
  border: none;
  background-color: #042e7d;
  color: #fff;
  font-size: 16px;
  font-family: 'Pretendard-SemiBold';
}
/* Chrome, Firefox, Opera, Safari 10.1+ */
input:focus::placeholder {
  color: transparent;
}
textarea:focus::placeholder {
  color: transparent;
}
/* Edge */
input:focus::-ms-input-placeholder {
  color: transparent;
}
textarea:focus::-ms-input-placeholder {
  color: transparent;
}
