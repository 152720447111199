@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff')
    format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard-Bold';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff')
    format('woff');
  font-weight: 700;
  font-style: normal;
}

.place-regist-container {
  width: calc(100vw - 300px);
  height: auto;
  background-color: #f1f3f9;
}

.place-regist-contents {
  max-width: 1200px;
  width: 100%;
  margin: 80px auto 0 auto;
}
.place-regist-top-wrap {
  display: flex;
}
.place-regist-left-wrap {
  margin-right: 100px;
}

.place-regist-right-map{
  width: 500px;
  height: 300px;
  background-color: #19277e;
}
.place-regist-right-map img {
  border-radius: 10px;
}
.place-regist-name label,
.place-regist-adress label,
.place-regist-adress-detail label,
.place-regist-call label,
.place-regist-work-type label:first-child,
.place-regist-overtime label:first-child,
.place-regist-overtime-allow label,
.place-regist-manager label {
  font-family: 'Pretendard-Bold';
  color: #19277e;
  margin-right: 44px;
}




.place-regist-location label:first-child {
  font-family: 'Pretendard-Bold';
  color: #19277e;
  margin-right: 44px;
  margin-left: 0;
}
.place-regist-location label {
  font-family: 'Pretendard-Bold';
  color: #19277e;
  margin-right: 8px;
  margin-left: 8px;
}
.place-regist-name input {
  width: 1078px;
  height: 55px;
  border-radius: 10px;
  border: none;
  margin-bottom: 25px;
  outline: none;
  padding-left: 20px;
}
.place-regist-location input {
  width: 211px;
  height: 55px;
  border-radius: 10px;
  border: none;
  margin-bottom: 25px;
  outline: none;
  padding-left: 20px;
}
.place-regist-adress input,
.place-regist-adress-detail input,
.place-regist-call input {
  width: 466px;
  height: 55px;
  border-radius: 10px;
  border: none;
  margin-bottom: 25px;
  outline: none;
  padding-left: 20px;
}

.place-regist-name input::placeholder,
.place-regist-adress input::placeholder,
.place-regist-location input::placeholder,
.place-regist-adress-detail input::placeholder,
.place-regist-call input::placeholder {
  /* padding-left: 20px; */
  margin: 20px;
  font-family: 'Pretendard-Regular';
}

.place-regist-work-type {
  margin: 55px 0 25px 0;
  display: flex;
}
.place-regist-work-type p {
  font-family: 'Pretendard-Bold';
  color: #19277e;
  margin-right: 60px;
}
.place-regist-work-type input {
  margin-right: 10px;
}
.place-regist-work-type label {
  margin-right: 30px;
  font-family: 'Pretendard-Regular';
}
.place-regist-work-type label:first-child {
  margin-right: 60px;
}
.place-regist-work-plan p {
  font-family: 'Pretendard-Bold';
  color: #19277e;
  margin-bottom: 25px;
}
.place-regist-work-plan table {
  width: 100%;
  height: 220px;
  margin-bottom: 80px;
  background-color: #fff;
  border-radius: 10px;
  font-size: 15px;
  font-family: 'Pretendard-Regular';
  text-align: center;
  border-spacing: 0;
}
.place-regist-work-plan table thead {
  background-color: #19277e;
  height: 55px;
  color: #fff;
  font-family: 'Pretendard-Bold';
}
.place-regist-work-plan table thead tr th {
  border-radius: 10px 0 0 0;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  box-sizing: border-box;
}
.place-regist-work-plan table thead tr td:last-child {
  border-radius: 0 10px 0 0;
}
.place-regist-work-plan table tbody tr:last-child th {
  border-radius: 0 0 0 10px;
}
.place-regist-work-plan table th {
  text-align: left;
  background-color: #19277e;
  color: #fff;
  width: 120px;
  padding-left: 20px;
}
.place-regist-work-plan table td {
  width: 154px;
  border-bottom: 1px solid #c4c4c4;
}
.place-regist-work-plan table tr:last-child td {
  border-bottom: none;
}
.place-regist-overtime {
  margin-bottom: 25px;
}
.place-regist-overtime label {
  margin-right: 30px;
}
.place-regist-overtime label:first-child {
  margin-right: 144px;
}
.place-regist-overtime-allow label:first-child {
  margin-right: 81px;
}
.place-regist-overtime-allow input,
.place-regist-manager input {
  margin-bottom: 25px;
  width: 466px;
  height: 55px;
  border-radius: 10px;
  border: none;
  outline: none;
  padding-left: 20px;
}
.place-regist-manager {
  margin-bottom: 25px;
}
.place-regist-manager label:first-child {
  margin-right: 162px;
}
.place-regist-button {
  margin: 0 auto;
  max-width: 180px;
}
.place-regist-button button {
  margin-bottom: 60px;
  margin-top: 40px;
  width: 180px;
  height: 60px;
  border-radius: 10px;
  border: none;
  background-color: #19277e;
  color: #fff;
  font-size: 16px;
  font-family: 'Pretendard-Bold';
  cursor: pointer;
}

/* radio */
[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  font-family: 'Pretendard-Regular';
}
[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 15px;
  height: 15px;
  border: 2px solid #19277e;
  border-radius: 100%;
  background: transparent;
}
[type='radio']:checked + label:before {
  border: 2px solid #19277e;
  border-radius: 100%;
}
[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  width: 9px;
  height: 9px;
  background: #19277e;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
