@font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff')
      format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Pretendard-Bold';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff')
      format('woff');
    font-weight: 700;
    font-style: normal;
  }
  
  .users-detail-container {
    width: 100%;
    /* height: auto; */
  }
  
  .users-detail-contents {
    width: 100%;
    max-width: 1200px;
    max-height:calc(100vh - 15vh);
    margin: 80px auto 0 auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.25);
    font-family: 'Pretendard-Regular';
    overflow: scroll;
  }
  .users-detail-contents-top {
    width: 100%;
    max-width: 1200px;
    position: fixed;
    z-index: 99999;
    background-color: #4d77ee;
    /* background-color: #19277e; */
    /* height: 88px; */
    padding: 15px;
    /* border-radius: 10px 10px 0 0; */
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-family: 'Pretendard-Bold';
  }
  .users-detail-contents-top-inner {
      display: flex;
      width: 100%;
      justify-content: space-between;
  }
  .users-detail-contents-top-inner div {
      display: flex;
  }
  .users-detail-contents-top div div {
      margin: 30px 35px;
  }
  .users-detail-CircleUser {
      width: 26px;
      height: 26px;
      margin-right: 12px;
  }
  .users-detail-contents-top .users-detail-modified {
    font-size: 15px;
    font-weight: normal;
  }
  .users-detail-contents-top h3 {
      font-size: 22px;
  }
  .users-detail-Xmark {
    height: 24px;
    width: 24px;
    margin: 12px 14px 0 0;
    cursor: pointer;
  }
  .users-detail-contents-inner {
      width: 90%;
      max-width: 1080px;
      margin: 123px auto 40px auto;
  }
  .users-detail-contents-inner h4 {
    font-family: 'Pretendard-Bold';
    margin-bottom: 10px;
  }

  .users-detail-info .users-detail-modified{
    font-size: 10px;
    margin-left: 5px;
  }



  .user-detail-field {
    width:100%;
    display: flex;
    /* align-items: center; */
    height: 45px;
    line-height: 44px;
    /* background-color: palegoldenrod; */
    border-bottom: 1px solid #dadada;
    
  }
  .user-detail-field >label{
    width:100px;
    margin-right: 15px;
    font-size: 14px;
    font-family: 'Pretendard-Bold';
    /* color: #19277e; */
    color: #333;
    background-color: #fafafa;
    border-right: '1px solid #d1d1d1';
    padding-left: 7px;
  }
  .user-detail-field>label > p{
    width:calc(100% - 100px);
    margin:0 !important;
    padding:0;
  }


.users-detail-work-place div {
    width: 100%;
    min-height: 297px;
    overflow: hidden;
}
.users-detail-work-place .place-contents-bottom-table .place-contents-bottom-table-content{
  cursor: auto;
  border-collapse: separate;
  border-bottom: none;
  border: 1px solid #c4c4c4;
}

.users-detail-work-place .place-contents-bottom-table .no-columns{
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: rgb(178, 216, 248);
  border-top: none;
  border-radius:0 0 10px 10px;
  border: 1px solid #c4c4c4;
}

.users-detail-work-place .place-contents-bottom-table .no-columns .no-columns-img{
  width: 100%;
  height: 150px;
  max-height: 150px;
  object-fit: contain;
  margin: 5px;
}

.users-detail-work-place .place-contents-bottom-table .place-contents-bottom-table-content:last-child {
  border-top: none;
  border: 1px solid #c4c4c4;
}

.users-detail-attendance{
  width: 100%;
}