.alert-container {
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999
}

/* ----------------------------------------------------------------------------------------------------- */
/* message-box */
.message-box {
    z-index: 999999;
    position: fixed;
    top: 10px;
    right: 10px;
    min-height: 100px;
    width: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.message-box .message-header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.message-box .message-header .type-icon {
    width: 20px;
    margin: 10px;
}

.message-box .message-header .message-title {
    width: 300px;
}

.message-box .message-header .message-close {
    flex: 1;
    cursor: pointer;
}

.message-box .message-content {
    width: 95%;
    flex: 1;
    min-height: 50px;
}

.message-box-faCircleCheck {
    width: 20%;
    height: 20px;
    color: #556FDF;
    /* margin: 10px; */
}

.message-box-blue div:nth-child(2) {
    margin: 10px;
}

.message-box-faXmark {
    margin: 10px;
}


.message-box-faCircleXmark {
    width: 20px;
    height: 20px;
    color: #F05C96;
    margin: 10px;
}

.message-box-red div:nth-child(2) {
    margin: 10px;
}


.message-box-faCircleQuestion {
    width: 20px;
    height: 20px;
    color: #ffc800;
    margin: 10px;
}

.message-box-yellow div:nth-child(2) {
    margin: 10px;
}

/* message-box-red */
.message-box-red {
    display: flex;
    background-color: #fce2ee;
    border: 1px solid #F05C96;
    border-radius: 4px;
}

/* message-box-blue */
.message-box-blue {
    display: flex;
    background-color: #DAE6FE;
    border: 1px solid #556FDF;
    border-radius: 4px;
}

/* message-box-yellow */
.message-box-yellow {
    display: flex;
    background-color: #fff7db;
    border: 1px solid #fdd019;
    border-radius: 4px;
    width: 20%;
}