@charset "utf-8";

/* 스타일 초기화 */
* {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: #333;
  display: block;
}

@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff')
    format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard-Bold';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff')
    format('woff');
  font-weight: 700;
  font-style: normal;
}

html,
body,
#root {
  overflow: visible !important;
}

.mainlayout-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  /* border: 1px solid rgb(0, 255, 119); */
}
/* header */
.header {
  display: flex;
  justify-content: first-start;
  height: 80px;
}
.header-logo {
  padding-left: 30px;
  width: 220px;
  padding-right: 50px;
}
.header-logo img {
  width: 220px;
  height: 100%;
}
.header-current-menu-wrap {
  line-height: 80px;
}
.header-current-menu {
  display: flex;
  width: calc(100vw - 543px);
  padding-left: 32px;
}
.header-current-menu .header-angle-left-icon {
  margin: auto 0 auto 0;
  height: 20px;
  margin-right: 20px;
}
.header-current-menu h1 {
  font-size: 20px;
  font-family: 'Pretendard-Bold';
}
.header-right-wrap {
  width: 210px;
}
.header-right {
  display: flex;
  height: 80px;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 32px;
}
.header-right-notification {
  margin: auto 0 auto 0;
}
.header-right-notification .header-bell-icon {
  height: 20px;
}
.header-right-user {
  display: flex;
}
.header-right-user .header-angle-down-icon {
  width: 20px;
  margin: auto 0 auto 3px;
}
.header-right-user p {
  margin: auto 0 auto 0;
  font-family: 'Pretendard-Regular';
}
.header-right-user-icon {
  margin: auto 0 auto 0;
}
.header-circle-user-icon {
  height: 24px;
  margin: auto;
}

/* sidemenu */
.sidemenu-wrap {
  height: 100vh;
  width: 300px;
}
.sidemenu ul li:first-child {
  padding-top: 10%;
}
.sidemenu ul li:nth-child(5) {
  padding-bottom: 540px;
}
.sidemenu ul li a {
  display: flex;
  padding: 30px 30px;
}
.sidemenu ul li:last-child {
  border-top: 1px solid #c4c4c4;
}
.sidemenu ul li a svg {
  height: 19px;
}
.MenuDashboardIcon {
  height: 19px;
  margin-right: 18px;
}
.LocationDot {
  margin-right: 23px;
}
.User {
  margin-right: 20px;
}
.Comments {
  margin-right: 14px;
}
.Gear {
  margin-right: 18px;
}
.ArrowRightFromBracket {
  margin-right: 20px;
}
.sidemenu ul li a p {
  font-size: 16px;
  font-family: 'Pretendard-SemiBold';
}

/* main-content */
.main-content-wrap {
  display: flex;
}
.main-dashboard-content {
  width: calc(100vw - 300px);
  background-color: #f1f3f9;
}
.main-dashboard-content-top {
  margin-left: 210px;
  margin-right: 210px;
}
.main-dashboard-content-top-title {
  display: flex;
  margin-top: 80px;
  margin-bottom: 35px;
}
.main-dashboard-content-top-title h2 {
  font-family: 'Pretendard-Bold';
  font-size: 20px;
  line-height: 27px;
  margin-right: 16px;
}
.main-dashboard-content-top-title h3 {
  font-family: 'Pretendard-Regular';
  font-size: 13px;
  background-color: #19277e;
  border-radius: 50px;
  width: 182px;
  height: 27px;
  line-height: 27px;
  color: #fff;
  text-align: center;
  cursor: pointer;
}
.main-dashboard-content-top-inner {
  display: flex;
  margin-bottom: 80px;
}
.main-dashboard-content-top-inner-box {
  display: flex;
  background-color: #fff;
  width: 218px;
  height: 90px;
  border-radius: 10px;
  margin-right: 27.5px;
}
.main-dashboard-content-top-inner-box:last-child {
  margin-right: 0;
}
.main-dashboard-content-top-inner-box-img {
  margin: auto 58px auto 18px;
}
.main-dashboard-content-top-inner-box-txt {
  margin: auto 0 auto 0;
}
.main-dashboard-content-top-inner-box-txt p:first-child {
  color: #a3aed0;
  font-size: 13px;
  font-family: 'Pretendard-Regular';
  margin-bottom: 11px;
}
.main-dashboard-content-top-inner-box-txt p:nth-child(2) {
  font-size: 17px;
  font-family: 'Pretendard-Bold';
}
.main-dashboard-content-top-inner-box-txt span {
  font-size: 34px;
  margin-right: 20px;
}

.main-dashboard-content-bottom {
  margin-left: 210px;
  margin-right: 210px;
  max-width: 1200px;
}
.main-dashboard-content-bottom-title {
  margin-bottom: 35px;
}
.main-dashboard-content-bottom-title h2 {
  font-family: 'Pretendard-Bold';
  font-size: 20px;
}
.main-dashboard-content-bottom-table-header {
  width: calc(100vw - 720px);
  background-color: #19277e;
  border-radius: 10px 10px 0 0;
  height: 60px;
  line-height: 60px;
  text-align:left;
}
.main-dashboard-content-bottom-table-header li {
  display: flex;
  justify-content: space-around;
}
.main-dashboard-content-bottom-table-header li div {
  font-family: 'Pretendard-Bold';
  font-size: 15px;
  color: #fff;
}
.main-dashboard-content-bottom-table-content {
  width: calc(100vw - 720px);
  background-color: #fff;
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #c4c4c4;
}
.main-dashboard-content-bottom-table-content:last-child {
  border-bottom: none;
  border-radius: 0 0 10px 10px;
}
.main-dashboard-content-bottom-table-content li {
  display: flex;
  justify-content: space-around;
}
.main-dashboard-content-bottom-table-content li div {
  font-family: 'Pretendard-Regular';
  font-size: 15px;
}
.main-dashboard-content-bottom-pagination {
  display: flex;
  justify-content: flex-end;
  margin: 25px 0;
  width: calc(100vw - 720px);
}
.pagination-angle-icon-wrap {
  line-height: 44px;
  margin-right: 20px;
}
.pagination-angle-icon-wrap-next {
  line-height: 44px;
  margin-left: 10px;
}
.pagination-angle-icon {
  width: 10px;
  height: 19px;
  color: #19277e;
  margin: auto;
}
.main-dashboard-content-bottom-pagination-number {
  width: 33px;
  height: 39px;
  background-color: #fff;
  color: #19277e;
  margin-right: 10px;
  border-radius: 10px;
  text-align: center;
  line-height: 39px;
  font-size: 15px;
  font-family: 'Pretendard-Bold';
}
