@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff')
    format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard-Bold';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff')
    format('woff');
  font-weight: 700;
  font-style: normal;
}

.place-container {
  width: calc(100vw - 300px);
  height: 104vh;
  background-color: #f1f3f9;
}
.place-contents-top {
  display: flex;
  width: calc(100vw - 720px);
  justify-content: space-between;
  margin-top: 80px;
  margin-left: 210px;
  margin-bottom: 30px;
}
.place-contents-top-search {
  display: flex;
  width: 384px;
  height: 50px;
  background-color: #fff;
  border-radius: 10px;
  line-height: 50px;
  margin-top: 5px;
}
.place-contents-top-search-icon {
  margin: auto 9px auto 22px;
  color: #19277e;
  height: 12px;
}
.place-contents-top-search p {
  font-size: 14px;
  color: #8f9bba;
  font-family: 'Pretendard-Regular';
}
.place-contents-top-button button {
  width: 180px;
  height: 60px;
  border-radius: 10px;
  border: none;
  background-color: #19277e;
  color: #fff;
  font-size: 16px;
  font-family: 'Pretendard-Bold';
  cursor: pointer;
}
.place-contents-bottom {
  margin-left: 210px;
  width: calc(100vw - 720px);
}
.place-contents-bottom-table-header {
  background-color: #19277e;
  border-radius: 10px 10px 0 0;
  height: 60px;
  line-height: 60px;
}
.place-contents-bottom-table-header li {
  display: flex;
  justify-content: center;
}
.place-contents-bottom-table-header li div {
  font-family: 'Pretendard-Bold';
  font-size: 15px;
  color: #fff;
  width: 200px;
  text-align: center;
}
.place-contents-bottom-table-content {
  background-color: #fff;
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #c4c4c4;
  cursor: pointer;
}
.place-contents-bottom-table-content:last-child {
  border-bottom: none;
  border-radius: 0 0 10px 10px;
}
.place-contents-bottom-table-content li {
  display: flex;
  justify-content: center;
}
.place-contents-bottom-table-content li div {
  font-family: 'Pretendard-Regular';
  font-size: 15px;
  width: 200px;
  text-align: center;
}
